<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" v-can:hide.distributor />
        <filter-province class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" v-can:hide.distributor />
        <filter-area
          class="mt-2"
          v-model:value="area"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:region="region"
          v-can:hide.distributor
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota class="mr-2 mt-2" v-model:value="kabupaten" v-model:area="area" v-can:hide.distributor />
        <filter-lt
          class="mr-2 mt-2"
          v-model:value="lt"
          v-model:lt="lt"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"
        />
        <a-select
          :options="[
            { value: null, label: 'Semua' },
            { value: 2, label: 'Approve ASM' },
            { value: 1, label: 'Approve TSO' },
            { value: 0, label: 'New' },
            { value: 6, label: 'Hapus' },
          ]"
          class="mt-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="status"
        ></a-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          title="Menyetujui Toko"
          type="primary"
          @click="showConfirm"
          v-has-access="['customers.approve']"
        >
          <i class="fa fa-check" aria-hidden="true"></i>
        </a-button>
        <a-modal
          title="Konfirmasi Penyetujuan Toko"
          v-model:visible="modal5Visible"
          @ok="confirm"
          :confirm-loading="loading"
        >
          <p v-if="selectedRowKeys.length === 0">Checklist toko yang akan di setuju</p>
          <p v-else>Anda yakin ingin menyetujui toko?</p>
        </a-modal>

        <a-button
          class="ml-2 mr-2"
          title="Rejected / Hapus"
          type="danger"
          @click="showDelete"
          v-has-access="['customers.destroy']"
        >
          <i class="fa fa-ban" aria-hidden="true"></i>
          <a-modal
            title="Informasi"
            v-model:visible="modal2Visible"
            @ok="hapus"
            :confirm-loading="loading"
          >
            <p v-if="selectedRowKeys.length === 0">Checklist toko yang akan di hapus</p>
            <p v-else>Anda yakin ingin menonaktifkan toko terpilih?</p>
          </a-modal>
        </a-button>

        <a-button
          title="Tambah Toko"
          type="primary"
          v-has-access="['customers.store']"
          @click="
            () => {
              formState = {}
              formState.status_approval = 0
              formState.type = 2
              modal3Visible = true
              formState.title_state = 'Tambah Toko'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template
          #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div class="custom-filter-dropdown">
            <a-input
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm)"
              >Search</a-button
            >
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
              >Reset</a-button
            >
          </div> </template
        ><template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #active="{ text }">
          <a-tag v-if="text" color="#108ee9">Aktif</a-tag>
          <a-tag v-else color="grey">Tidak Aktif</a-tag>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#108ee9">Disetujui oleh ASM</a-tag>
          <a-tag v-else color="grey">Belum disetujui oleh ASM</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Toko">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="lihatPengguna(record.id)"
                v-has-access="['customers.show']"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Toko">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                v-has-access="['customers.update']"
                @click="editPengguna(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
  <a-modal
    v-model:visible="modal3Visible"
    :title="formState.title_state"
    :destroy-on-close="true"
    width="1000px"
    :mask-closable="false"
    @ok="modal3Visible = false"
    v-if="modal3Visible"
  >
    <pre v-if="!true">{{ formState }}</pre>
    <template #footer>
      <a-button
        :loading="isPosting"
        key="submit"
        type="primary"
        @click="submit"
        :hidden="formState.readOnly"
        >Simpan</a-button
      >
    </template>
    <m-modal ref="modalRef"></m-modal>
  </a-modal>

  <!-- <a-modal
    v-model:visible="modal5Visible"
    :title="formState.title_state"
    :destroy-on-close="true"
    width="1000px"
    :mask-closable="false"
    @ok="modal3Visible = false"
  >
    <template #footer>
      <a-button
        :loading="isPosting"
        key="submit"
        type="primary"
        @click="submit"
        :hidden="formState.readOnly"
        >Simpan</a-button
      >
    </template>
    <modall ref="modalRef"></modall>
  </a-modal> -->
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, h } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import MModal from './modal'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterLt from '@/components/filter/FilterLt'
// import FilterDistributor from '@/components/filter/FilterDistributor'
// import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import qs from 'qs'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
import useUserInfo from '@/composables/useUserInfo'

const acolumns = [
  {
    title: 'Tanggal Didaftarkan',
    dataIndex: 'registration_at',
  },
  {
    title: 'ID Toko',
    dataIndex: 'code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'name',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'distributor_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor_name',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
  },
  {
    title: 'Nama Pemilik Toko',
    dataIndex: 'owner',
  },
  {
    title: 'Region Toko',
    dataIndex: 'region',
  },
  {
    title: 'Provinsi Toko',
    dataIndex: 'provinsi',
  },
  {
    title: 'Area Toko',
    dataIndex: 'area',
  },
  {
    title: 'Kabupaten Toko',
    dataIndex: 'kabupaten',
  },

  {
    title: 'Kecamatan Toko',
    dataIndex: 'kecamatan',
  },
  {
    title: 'Kelurahan / Desa Toko',
    dataIndex: 'desa',
  },
  {
    title: 'Status Toko',
    // slots: { customRender: 'active' },
    dataIndex: 'status_text',
  },
  {
    title: 'Status Approval',
    dataIndex: 'status',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}
export default {
  name: 'MFilter',
  components: {
    MModal,
    // Modall,
    FilterRegional,
    FilterProvince,
    FilterArea,
    // FilterDistributor,
    FilterLt,
    // FilterWilayahSemua,
    FilterKabupatenKota,
  },
  setup() {
    const route = useRoute()
    const { isDistributor, vendor_id, regions, areas } = useUserInfo()
    // const modalRef = ref('')

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const modal4Visible = ref(false)
    const modal5Visible = ref(false)
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    const loading = ref(false)
    const handleChange = value => {
      console.log(value)
    }

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const lt = ref([])
    const area = ref([])
    const distributors = ref([])
    const modalRef = ref('')

    const dataRegional = ref([])
    const dataProvince = ref([])
    const dataArea = ref([])
    const status = ref(null)

    const columns = ref([])
    const data = ref([])
    const q = ref('')
    const searchInput = ref(null)
    const active = ref(null)

    const isPosting = ref(false)
    const formState = ref({
      status_approval: 0,
      foto_ktp: null,
      status_approval: 0,
      wilayah_id: null,
      type: 1,
      vendors: [],
    })

    const vendors = ref({
      vendor_id: [],
    })

    const errorMessage = ref(null)

    columns.value = acolumns
    if (isDistributor.value) {
      distributors.value.push(vendor_id.value)
      regions.value.forEach(item => {
        region.value.push(item.id)
      })
      areas.value.forEach(item => {
        area.value.push(item.id)
      })
    }

    const startRow = ref(1)
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        distributor: distributors.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        // produk: products.value,
        // brand: brands.value,
        lt: lt.value,
        status: status.value,
        q: q.value,
        active: active.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/lt', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const lihatPengguna = id => {
      apiClient.get(`/api/lt/${id}`).then(response => {
        const data = response.data
        formState.value = data
        modal3Visible.value = true
        formState.value.title_state = 'Lihat Toko'
        formState.value.readOnly = true
        apiClient
          .get(`/api/lt/${id}/codes`)
          .then(({ data }) => {
            formState.value.foto = formState.value.foto_toko
            formState.value.customerCodes = Object.assign([], data.items)
            formState.value.vendorRaw = formState.value.vendors.map(v => ({
              ...v,
              name: `${v.code} - ${v.name}`,
            }))
            formState.value.vendors = formState.value.vendors.map(v => v.id)
          })
          .catch(error => console.error(error))
      })
    }
    const editPengguna = id => {
      apiClient
        .get(`/api/lt/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal3Visible.value = true
          formState.value.foto = formState.value.foto_toko
          formState.value.title_state = 'Edit Toko'
          formState.value.state = 'edit'
          formState.value.readOnly = false
          apiClient
            .get(`/api/lt/${id}/codes`)
            .then(({ data }) => {
              formState.value.customerCodes = Object.assign([], data.items)
              formState.value.vendorRaw = formState.value.vendors.map(v => ({
                ...v,
                name: `${v.code} - ${v.name}`,
              }))
              formState.value.vendors = formState.value.vendors.map(v => v.id)
            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error({ failure: error }))
    }

    const search = () => {
      fetchData()
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/lt', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `master-lt_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const submit = async () => {
      try {
        await modalRef.value.formRef.validate()
      } catch (error) {
        console.log('submit', error)
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Ajukan Toko',
          content: 'Apakah anda ingin melanjutkan Ajukan Toko ?',

          onOk() {
            isPosting.value = true

            const form_data = serialize({
              ...formState.value,
              vendors: formState.value.vendors.map(id => ({ vendor_id: id })),
            })
            apiClient
              .post('/api/lt', form_data)
              .then(({ data }) => {
                const vendor_id = data.id
                const codes = formState.value.customerCodes
                const promises = []
                codes.forEach(c => {
                  c.vendor_id = vendor_id
                  const post = apiClient.post('/api/lt/' + vendor_id + '/codes', c).then(r => {
                    console.log('codes', r)
                  })
                  promises.push(post)
                })
                Promise.all(promises).catch(() =>
                  message.warning('Gagal simpan kode referensi!, silakan cek data Toko.'),
                )
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil ditambah')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data[0].message
                } else data.message = 'Gagal menyimpan data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data[0].message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit Toko',
          content: 'Apakah anda ingin melanjutkan Edit Toko ?',
          onOk() {
            isPosting.value = true

            // const form_data = new FormData()
            // for (var key in formState.value) {
            //   if (key === 'active' || key === 'kelengkapan_dokumen') {
            //     form_data.append(key, formState.value[key] ? 1 : 0)
            //   } else
            //     form_data.append(key, formState.value[key] === null ? '' : formState.value[key])
            // }
            const form_data = serialize({
              ...formState.value,
              vendors: formState.value.vendors.map(id => ({ vendor_id: id })),
            })
            apiClient
              .post('/api/lt/' + formState.value.id + '?_method=PUT', form_data)
              .then(({ data }) => {
                const vendor_id = data.id
                const codes = formState.value.customerCodes
                const promises = []
                codes
                  .filter(c => c.id === null)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const post = apiClient.post('/api/lt/' + vendor_id + '/codes', c).then(r => {
                      console.log('edit post codes', r)
                    })
                    promises.push(post)
                  })
                codes
                  .filter(c => c.is_deleted)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const _delete = apiClient
                      .delete('/api/lt/' + vendor_id + '/codes/' + c.id)
                      .then(r => {
                        console.log('delete codes', r)
                      })
                    promises.push(_delete)
                  })
                Promise.all(promises).catch(() =>
                  message.warning('Gagal simpan kode referensi!, silakan cek data Toko.'),
                )
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal mengupdate!'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      }
    }

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const visible = ref(false)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const showDelete = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist toko yang akan di nonaktifkan!',
          })
          return
        }
      }
      modal2Visible.value = visible
    }

    const hapus = () => {
      const promises = []
      state.loading = true
      state.selectedRowKeys.forEach(k => {
        promises.push(apiClient.delete('/api/lt/' + k + '?_method=PUT'))
      })

      Promise.all(promises)
        .then(() => {
          console.log('completed')
        })
        .finally(err => {
          state.selectedRowKeys = []
          modal2Visible.value = false
          formState.value = data
          fetchData()
          message.success('Berhasil dinonaktifkan')
        })
    }

    const showConfirm = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist toko yang akan di konfirmasi!',
          })
          return
        }
      }
      modal5Visible.value = visible
    }

    const confirm = id => {
      // approva by single post
      apiClient
        .post('/api/lt/approve', {
          ids: state.selectedRowKeys,
        })
        .then(res => {
          state.loading = true
          modal5Visible.value = false
          state.selectedRowKeys = []
          fetchData()
          state.loading = false

          let details = null
          if (res.data && res.data.details) {
            details = res.data.details.map((er, idx) => {
              if (!er.errors) return

              return h(
                Alert,
                { type: 'error', message: `Kode Toko (${er.code}) : ${er.errors[0]}` },
                '',
              )
            })
          }

          Modal.info({
            title: 'Approval',
            content: h('span', {}, [`${res.data.message}`, h(`span`, {}, details)]),
            onOk() {
              console.log('ok')
            },
          })
        })
        .catch(({ response: { data, status } }) => {
          if (status === 401) {
            data.message = 'Anda tidak memiliki hak untuk approve data ini!'
          }
          Modal.error({
            title: 'Approval',
            content: data.message,
            onOk() {
              console.log('ok')
            },
          })
        })
    }

    provide('formState', formState)

    onMounted(() => {
      const { distributor: disti_id } = route.query
      if (disti_id) {
        if (!distributor.value.includes(Number(disti_id))) distributor.value.push(Number(disti_id))
      }
      fetchData()
    })

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      rowSelection,
      columns,
      data,
      q,
      searchInput,
      startRow,
      region,
      provinsi,
      kabupaten,
      lt,
      area,
      distributors,
      state,
      ...toRefs(state),
      fetchData,
      search,
      modal2Visible,
      modal3Visible,
      modal4Visible,
      modal5Visible,
      setModal1Visible,
      fetchXlsx,
      isPosting,
      formState,
      onSelectChange,
      visible,
      editPengguna,
      lihatPengguna,
      handleTableChange,
      hapus,
      submit,
      dataRegional,
      dataProvince,
      dataArea,
      loading,
      confirm,
      modalRef,
      active,
      errorMessage,
      status,
      vendors,
      showConfirm,
      showDelete,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
